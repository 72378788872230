<template lang="pug">
  b-card.manager-machines-models-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.machine/model.one') }}
    b-form.form-wc.form-machines-model-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-model-name(
              :label="$t('activerecord.attributes.shared.name')"
              label-for='model-name'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-name.form-input-first.shadow-sm.is-required(
                name='name'
                type='text'
                autocomplete='name'
                :placeholder="$t('machine/model.placeholders.name')"
                :state='formValidationState($v.form.data.attributes.name)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.name.$model'
                aria-describedby='model-name-feedback'
                trim)
              wc-forms-if#model-name-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.name'
                :remote="formRemoteInvalidFeedback('name')"
                :validators='{ required: {}, maxLength: {} }')

            b-form-group#form-model-category(
              :label="$t('activerecord.models.category.one')"
              label-for='model-category_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#model-category_id.form-input-middle.shadow-sm.custom-select.is-required(
                name='category'
                v-model='modelCategoryIdSelected'
                :options='form.selects.modelCategoryIdOptions'
                :class='formValidationClass($v.form.data.attributes.category_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('machine/model.placeholders.category')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.modelCategoryIdLoading'
                @search-change='searchCategory'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}
              wc-forms-if#model-category_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.category_id'
                :remote="formRemoteInvalidFeedback('category')"
                :validators='{ required: {} }')

            b-form-group#form-model-weight(
              :label="$t('activerecord.attributes.machine/model.weight')"
              label-for='model-weight'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-weight.form-input-middle.shadow-sm.is-required(
                name='weight'
                type='text'
                autocomplete='weight'
                :placeholder="$t('machine/model.placeholders.weight')"
                :state='formValidationState($v.form.data.attributes.weight)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.weight.$model'
                aria-describedby='model-weight-feedback'
                trim)
              wc-forms-if#model-weight-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.weight'
                :remote="formRemoteInvalidFeedback('weight')"
                :validators='{ required: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-model-min_width(
              :label="$t('activerecord.attributes.machine/model.min_width')"
              label-for='model-min_width'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-min_width.form-input-middle.shadow-sm.is-required(
                name='min_width'
                type='text'
                autocomplete='min_width'
                :placeholder="$t('machine/model.placeholders.min_width')"
                :state='formValidationState($v.form.data.attributes.min_width)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.min_width.$model'
                aria-describedby='model-min_width-feedback'
                trim)
              wc-forms-if#model-min_width-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.min_width'
                :remote="formRemoteInvalidFeedback('min_width')"
                :validators='{ required: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-model-min_length(
              :label="$t('activerecord.attributes.machine/model.min_length')"
              label-for='model-min_length'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-min_length.form-input-middle.shadow-sm.is-required(
                name='min_length'
                type='text'
                autocomplete='min_length'
                :placeholder="$t('machine/model.placeholders.min_length')"
                :state='formValidationState($v.form.data.attributes.min_length)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.min_length.$model'
                aria-describedby='model-min_length-feedback'
                trim)
              wc-forms-if#model-min_length-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.min_length'
                :remote="formRemoteInvalidFeedback('min_length')"
                :validators='{ required: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-model-min_height(
              :label="$t('activerecord.attributes.machine/model.min_height')"
              label-for='model-min_height'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-min_height.form-input-middle.shadow-sm.is-required(
                name='min_height'
                type='text'
                autocomplete='min_height'
                :placeholder="$t('machine/model.placeholders.min_height')"
                :state='formValidationState($v.form.data.attributes.min_height)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.min_height.$model'
                aria-describedby='model-min_height-feedback'
                trim)
              wc-forms-if#model-min_height-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.min_height'
                :remote="formRemoteInvalidFeedback('min_height')"
                :validators='{ required: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-model-max_width(
              :label="$t('activerecord.attributes.machine/model.max_width')"
              label-for='model-max_width'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-max_width.form-input-middle.shadow-sm.is-required(
                name='max_width'
                type='text'
                autocomplete='max_width'
                :placeholder="$t('machine/model.placeholders.max_width')"
                :state='formValidationState($v.form.data.attributes.max_width)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.max_width.$model'
                aria-describedby='model-max_width-feedback'
                trim)
              wc-forms-if#model-max_width-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.max_width'
                :remote="formRemoteInvalidFeedback('max_width')"
                :validators='{ required: {}, greaterEqual: { number: this.form.data.attributes.min_width } }')

            b-form-group#form-model-max_length(
              :label="$t('activerecord.attributes.machine/model.max_length')"
              label-for='model-max_length'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-max_length.form-input-middle.shadow-sm.is-required(
                name='max_length'
                type='text'
                autocomplete='max_length'
                :placeholder="$t('machine/model.placeholders.max_length')"
                :state='formValidationState($v.form.data.attributes.max_length)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.max_length.$model'
                aria-describedby='model-max_length-feedback'
                trim)
              wc-forms-if#model-max_length-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.max_length'
                :remote="formRemoteInvalidFeedback('max_length')"
                :validators='{ required: {}, greaterEqual: { number: this.form.data.attributes.min_length } }')

            b-form-group#form-model-max_height.mb-4(
              :label="$t('activerecord.attributes.machine/model.max_height')"
              label-for='model-max_height'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#model-max_height.form-input-last.shadow-sm.is-required(
                name='max_height'
                type='text'
                autocomplete='max_height'
                :placeholder="$t('machine/model.placeholders.max_height')"
                :state='formValidationState($v.form.data.attributes.max_height)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.max_height.$model'
                aria-describedby='model-max_height-feedback'
                trim)
              wc-forms-if#model-max_height-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.max_height'
                :remote="formRemoteInvalidFeedback('max_height')"
                :validators='{ required: {}, greaterEqual: { number: this.form.data.attributes.min_height } }')

            wc-forms-translations(
              v-for='(locale, key) in $wc.conf.base.locales'
              :key='key'
              :locale='locale'
              :lc='key')
              b-collapse(
                :id='`tl-${key}`'
                :ref='`${key}Tl`'
                :visible="action === 'create' ? true : formShowSection(key)"
                role='tabpanel')
                b-card-body
                  b-form-group(
                    :id='`form-model-description_${key}`'
                    :label="$t('activerecord.attributes.shared.description') + ' ' + $t(`locales.${key}`)"
                    label-for='`model-description_${key}`'
                    label-sr-only)
                    b-form-textarea.shadow(
                      :id='`model-description_${key}`'
                      name='`description_${key}`'
                      :placeholder="$t('machine/model.placeholders.description')"
                      :state='formValidationState($v.form.data.attributes[`description_${key}`])'
                      @focus.native='formChange'
                      v-model='$v.form.data.attributes[`description_${key}`].$model'
                      aria-describedby='`model-description_${key}-feedback`'
                      rows='4'
                      max-rows='8'
                      trim)
                    wc-forms-if(
                      :id='`model-description_${key}-feedback`'
                      extra-class='ml-feedback'
                      :attribute='$v.form.data.attributes[`description_${key}`]'
                      :remote="formRemoteInvalidFeedback(`description_${key}`)"
                      :validators='{ maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-model-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsTranslations from '@components/shared/forms/WcFormsTranslations'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiModels from '@services/api/manager/globals/machines/models'
import { Form, mapSelects } from '@common/form'
import { greaterEqual } from '@common/form/validations'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-machines-models-form',
  mixins: [Form],
  components: {
    WcFormsTranslations,
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'modelCategoryId',
        default: 'relationships.category.data.id',
        attribute: 'attributes.category_id',
        included: { type: 'category', key: 'id', value: 'attributes.name' },
      },
    ]),
    apiParams() {
      return {
        get: [{ id: this.model_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.model_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        modelCategoryIdSearch: [
          { id: this.model_id },
          { term: this.form.selects.modelCategoryIdTerm },
        ],
      }
    },
  },
  methods: {
    searchCategory(term, id) {
      this.formMultiselectSearch(term, id, 'searchCategory', 'name_humanized')
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            name: {
              required,
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('name'),
            },
            category_id: {
              required,
              remote: () => this.formRemoteValid('category'),
            },
            weight: {
              required,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('weight'),
            },
            min_width: {
              required,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('min_width'),
            },
            min_length: {
              required,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('min_length'),
            },
            min_height: {
              required,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('min_height'),
            },
            max_width: {
              required,
              greaterEqual: greaterEqual(() => this.form.data.attributes.min_width),
              remote: () => this.formRemoteValid('max_width'),
            },
            max_length: {
              required,
              greaterEqual: greaterEqual(() => this.form.data.attributes.min_length),
              remote: () => this.formRemoteValid('max_length'),
            },
            max_height: {
              required,
              greaterEqual: greaterEqual(() => this.form.data.attributes.min_height),
              remote: () => this.formRemoteValid('max_height'),
            },
          },
        },
      },
    }
    for (let locale of this.$wc.conf.base.available_locales) {
      validations.form.data.attributes[`description_${locale}`] = {
        maxLength: maxLength(this.$wc.conf.limits.max_description),
        remote: () => this.formRemoteValid(`description_${locale}`),
      }
      validations.form.data.attributes[`${locale}Tl`] = [
        `form.data.attributes.description_${locale}`,
      ]
    }
    return validations
  },
  data() {
    return {
      apiBase: apiModels,
      apiModel: 'model',
      model_id: this.$route.params.model_id,
      asyncDataSelects: ['modelCategoryId'],
    }
  },
}
</script>
